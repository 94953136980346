import { RemixBrowser } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { getEnv } from '../src/config/getEnv';
import { sentry } from '../src/config/sentry';
import { SessionTracking } from './sentry/SessionTracking';

// use the inline function so we can avoid the import
function getQueryParam(key: string): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

const env = getEnv();
const sentryConfig = sentry(env);

if (sentryConfig.dsn !== '' && getQueryParam('sentry') !== 'disabled') {
  Sentry.init({
    dsn: sentryConfig.dsn,
    tracesSampleRate: sentryConfig.tracesSampleRate,
    release: sentryConfig.buildSHA,
    autoSessionTracking: false,
    integrations: [new SessionTracking()],

    // Avoid "invalid event envelop" errors:
    // https://github.com/getsentry/sentry-javascript/issues/4404#issuecomment-1013146388.
    // When we upgrade to >=v21.9.0, we can re-enable these.
    // https://develop.sentry.dev/sdk/client-reports/
    sendClientReports: false,

    beforeSend(event, hint) {
      const oerr = hint?.originalException;

      if (!oerr || typeof oerr !== 'object') return event;

      const code = 'code' in oerr ? String(oerr['code']) : '';
      const name = 'name' in oerr ? String(oerr['name']) : '';
      const message = 'message' in oerr ? String(oerr['message']) : '';

      if (code && name && name.match(/agora/i)) {
        // likely an agora "Error" that the SDK constructed and threw, but it's
        // not actually an error instance :O. This results in Sentry / the
        // browser reporting "Non-Error promise rejection captured with keys:
        // code, data, message, name" rather than a useful error. Override
        // Sentry's fingerprinting to hopefully group by the values agora uses
        // (code, name).

        // If events become too specific and are not being grouped, consider
        // removing `message` from the fingerprint array.
        event.fingerprint = [name, code, message ?? ''];

        event.extra = event.extra ?? {};
        event.extra.code = code;
        event.extra.name = name;
        event.extra.message = message;

        // Prevent it from appearing as UnhandledRejection
        if (event.exception?.values?.[0]?.type === 'UnhandledRejection') {
          event.exception.values[0] = {
            type: name,
            value: message,
            mechanism: {
              handled: false,
              type: 'AgoraErrorlikeUnhandledPromiseRejectionTransformer',
            },
          };
        }
      }

      return event;
    },

    ignoreErrors: [
      // Chrome, Firefox, Safari deliver a warning about cascading resize
      // observer effects directly to window.onerror. It is practically
      // uncatchable across all browsers. It generally means that a
      // resizeobserver callback, observing a child, has triggered a resize of a
      // parent element in the tree. See
      // https://github.com/OliverJAsh/resize-observer-loop-tests for some
      // common scenarios.
      /ResizeObserver(.+)loop/i,
    ],

    beforeBreadcrumb(breadcrumb, _hint) {
      // The Breadcrumb types are _really_ loose, but this is where XHR
      // integration happens, which specifies which properties might be there:
      // https://github.com/getsentry/sentry-javascript/blob/249e64d02efc4ae60626aaaba892593616c9dec9/packages/browser/src/integrations/breadcrumbs.ts#L177-L191

      // Drop these internal, constant URLs from appearing in Sentry.
      if (breadcrumb.data?.url?.match?.(/statscollector-[\d]+\.agora\.io/))
        return null;

      // Prevent spammy agora console messages from getting sent as breadcrumbs as:
      // `19:44:07:537 %cAgora-SDK [DEBUG]: color: #64B5F6;
      // [lock-P2PConnection-mutex-52] is not locked, current queue 0. From
      // P2PConnection.stopSending`
      if (
        breadcrumb.category === 'console' &&
        breadcrumb.message?.match(/Agora-SDK \[/)
      )
        return null;

      return breadcrumb;
    },
  });
}

// Purposefully using process.env so it gets compiled out during build
if (process.env.NODE_ENV === 'development') {
  // Augments webgl APIs for better developer debugging
  import('webgl-lint');
}

// NOTE(drew): this is the result of running `pnpm exec remix expose`. Avoid
// adding anything here, as it's best to add it to the route entry files
// instead.

startTransition(() => {
  hydrateRoot(
    document,
    // <StrictMode>
    <RemixBrowser />
    // </StrictMode>
  );
});
