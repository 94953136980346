import { makeSession } from '@sentry/core';
import type {
  EventProcessor,
  Hub,
  Integration,
  SerializedSession,
  Session,
} from '@sentry/types';
import { dropUndefinedKeys } from '@sentry/utils';

// Taken from: https://github.com/getsentry/sentry-javascript/discussions/7692.
// Apparently the default SessionTracking uses Navigation onchange, which causes
// even hashchanges to cause a new session!

export class SessionTracking implements Integration {
  public static id = 'SessionTracking';
  public name = SessionTracking.id;
  private _hasSessionStorage: boolean = 'sessionStorage' in window;

  public setupOnce(
    _addGlobalEventProcessor: (callback: EventProcessor) => void,
    getCurrentHub: () => Hub
  ): void {
    if (!this._hasSessionStorage) {
      return;
    }
    const hub = getCurrentHub();

    // Attempt to get session from storage.
    const existingSerializedSession = this._getSession();
    if (existingSerializedSession) {
      // If it exists, set it on the scope so that errors can be associated with it.
      hub
        .getScope()
        .setSession(this._deserializeSession(hub, existingSerializedSession));
    } else {
      // If it doesn't exist, create a new session and set it on the scope.
      const session = hub.startSession();
      hub.captureSession();
      // Store this new session in session storage.
      this._setSession(session);
    }
  }

  private _setSession(session: Session): void {
    try {
      window.sessionStorage.setItem(
        '__SENTRY_SDK_SESSION__',
        JSON.stringify(session)
      );
    } catch {
      // this shouldn't happen
    }
  }

  private _getSession(): SerializedSession | undefined {
    try {
      const stringSession = window.sessionStorage.getItem(
        '__SENTRY_SDK_SESSION__'
      );
      if (stringSession) {
        return JSON.parse(stringSession) as SerializedSession;
      }
    } catch {
      // this shouldn't happen
    }

    return undefined;
  }

  private _deserializeSession(_hub: Hub, session: SerializedSession): Session {
    return makeSession(
      dropUndefinedKeys({
        sid: session.sid,
        init: false,
        started: new Date(session.started).getTime(),
        timestamp: new Date(session.timestamp).getTime(),
        status: session.status,
        errors: session.errors,
        did: session.did,
        duration: session.duration,
        release: session.attrs?.release,
        environment: session.attrs?.environment,
        ipAddress: session.attrs?.ip_address,
        userAgent: session.attrs?.user_agent,
      })
    );
  }
}
